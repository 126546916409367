.swiper-container {
  width: 100%;
}

.swiper-button-prev {
  left: 50%;
  margin-left: -590px;
  -webkit-transform: translate(-200%, -50%);
  -ms-transform: translate(-200%, -50%);
  transform: translate(-200%, -50%);
  color: #7fba2f;
}

.swiper-button-next {
  right: 50%;
  margin-right: -590px;
  -webkit-transform: translate(200%, -50%);
  -ms-transform: translate(200%, -50%);
  transform: translate(200%, -50%);
  color: #7fba2f;
}

.swiper-wrapper {
  padding-inline-start: 0;
  margin-block-start: 0;
  height: 400px;
}

.swiper-pagination {
  bottom: 0;
  padding-bottom: 10px;
}
